<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Add Service</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link
				to="/on-trip-services/list"
				class="btn btn-light mb-4 btn-sm"
				>
				Go back
			</router-link>
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Add a New Service</h4>
							</div>
						</div>
						<div class="card-body">
							<div
								class="alert alert-danger alert-dismissible fade show"
								role="alert"
								v-if="errorMessage"
								>
								{{ errorMessage }}
							</div>
							<form @submit.prevent="addService">
								<div class="form-group row">
									<div class="col-12">
										<label class="form-label">Service Brand</label>
										<input
											v-model.trim="service.brand"
											required
											type="text"
											class="form-control"
											/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-12">
										<label class="form-label">Name</label>
										<input
											v-model.trim="service.name"
											required
											type="text"
											class="form-control"
											/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-12 col-md-6">
										<label class="form-label">Price</label>
										<input
											v-model.trim="service.price"
											required
											type="number"
											class="form-control"
											/>
									</div>
									<div class="col-12 col-md-6">
										<label class="form-label">Quantity</label>
										<input
											v-model.trim="service.quantity"
											required
											type="number"
											class="form-control"
											/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-12 col-md-6">
										<label class="form-label">Status</label>
										<select
											v-model="service.status"
											class="form-control form-select"
											>
											<option value="1">Active</option>
											<option value="0">Disabled</option>
										</select>
									</div>
									<div class="col-12 col-md-6">
										<label class="form-label">Product/Service Image</label>
										<input
											type="file"
											accept="image/*"
											class="form-control"
											@change="prepareImageUpload"
											/>
									</div>
								</div>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{ processing ? 'Processing...' : 'Add Service' }}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ['serviceId'],
  data () {
    return {
      service: {
        name: '',
        price: '',
        status: '1',
        quantity: '',
        image: ''
      },
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false
    }
  },
  methods: {
    addService () {
      this.processing = true

      this.errorMessage = ''

      this.axios
        .post('/v1/services/', {
          name: this.service.name,
          price: this.service.price,
          status: parseInt(this.service.status, 10),
          quantity: this.service.quantity,
          image: this.service.image ? this.service.image : 'NULL'
        })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: 'Service Added',
            text: 'Service has been added successfully',
            showCloseButton: true
          })

          this.$router.push({
            name: 'ShowService',
            params: { serviceId: res.data.id }
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    },
    prepareImageUpload (event) {
      this.updateServiceModel.image = event.target.value
    }
  }
}
</script>
